var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": [_vm.componentId],
            scrollable: "",
          },
          on: { ok: _vm.modalOk, hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _vm.exists
                    ? [
                        _c(
                          "b-button",
                          {
                            staticClass: "history-button",
                            attrs: { variant: "secondary", size: "sm" },
                            on: {
                              click: function ($event) {
                                _vm.state.historyShow = true
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "clock-rotate-left"] },
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("button.history")) + " "
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  !_vm.readOnly &&
                  ((_vm.exists && _vm.canEdit()) ||
                    (!_vm.exists && _vm.canAdd()))
                    ? [
                        _vm.state.isSubmitting
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "success",
                                  disabled: "",
                                },
                              },
                              [
                                _c("b-spinner", {
                                  attrs: { small: "", type: "grow" },
                                }),
                                _vm._v(_vm._s(_vm.$t("button.saving")) + " "),
                              ],
                              1
                            )
                          : !_vm.isAccessDenied
                          ? _c(
                              "b-button",
                              {
                                attrs: { size: "sm", variant: "success" },
                                on: { click: _vm.modalOk },
                              },
                              [_vm._v(_vm._s(_vm.$t("button.ok")))]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _vm.isAccessDenied
            ? [
                _c("div", { staticClass: "modal-message-overlay" }, [
                  _c("span", { staticClass: "grid-overlay" }, [
                    _vm._v(
                      _vm._s(
                        _vm.restrictedRequiredField != null
                          ? _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity_with_reason",
                              [
                                _vm.$t("contact.title_singular").toLowerCase(),
                                _vm.restrictedRequiredField,
                              ]
                            )
                          : _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity",
                              [_vm.$t("contact.title_singular").toLowerCase()]
                            )
                      )
                    ),
                  ]),
                ]),
              ]
            : [
                _c("AvatarBanner", {
                  attrs: { readOnly: _vm.isReadOnly },
                  on: { status: _vm.avatarBannerStatus },
                  model: {
                    value: _vm.avatarBanner,
                    callback: function ($$v) {
                      _vm.avatarBanner = $$v
                    },
                    expression: "avatarBanner",
                  },
                }),
                _c(
                  "b-alert",
                  {
                    attrs: {
                      variant: "danger",
                      dismissible: "",
                      show: _vm.showAlert,
                    },
                    on: { dismissed: _vm.dismissAlert },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "triangle-exclamation"] },
                    }),
                    _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
                    _c(
                      "ul",
                      {
                        staticClass: "mb-0",
                        attrs: { show: _vm.showErrorDetail },
                      },
                      [
                        _vm._l(_vm.alertMsgDetails, function (item, index) {
                          return [
                            _c("li", { key: index }, [_vm._v(_vm._s(item))]),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _vm.canView(_vm.permissionName, ["firstName"])
                      ? _c(
                          "b-col",
                          { attrs: { cols: "12", md: "8" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.$t("contact.field.firstName"),
                                  "label-for": "firstName",
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: { required: true },
                                          expression: "{ required: true }",
                                        },
                                      ],
                                      attrs: {
                                        id: "firstName",
                                        type: "text",
                                        "data-vv-as": _vm.$t(
                                          "contact.field.firstName"
                                        ),
                                        "data-vv-name": "contact.firstName",
                                        "data-vv-delay": "500",
                                        readonly:
                                          _vm.isReadOnly ||
                                          (!_vm.exists &&
                                            !_vm.canAdd(_vm.permissionName, [
                                              "firstName",
                                            ])) ||
                                          (_vm.exists &&
                                            !_vm.canEdit(_vm.permissionName, [
                                              "firstName",
                                            ])),
                                        state:
                                          _vm.fieldValidateUtil.stateValidate(
                                            _vm.isReadOnly,
                                            _vm.veeFields,
                                            _vm.errors,
                                            "contact.firstName"
                                          ),
                                      },
                                      model: {
                                        value: _vm.contact.firstName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.contact,
                                            "firstName",
                                            $$v
                                          )
                                        },
                                        expression: "contact.firstName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-invalid-feedback",
                                  {
                                    staticClass:
                                      "alert-danger form-field-alert",
                                    class: {
                                      "d-block": _vm.showFirstNameError,
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: ["far", "circle-exclamation"],
                                      },
                                    }),
                                    _vm._v(
                                      "  " +
                                        _vm._s(
                                          _vm.errors.first("contact.firstName")
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.customFieldMap["firstName"] != null
                      ? _vm._l(
                          _vm.customFieldMap["firstName"],
                          function (field, index) {
                            return _c(
                              "b-col",
                              {
                                key: "firstName" + index,
                                staticClass: "pr-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    field.type !== "Boolean"
                                      ? _c("template", { slot: "label" }, [
                                          _c("span", { staticClass: "mr-2" }, [
                                            _vm._v(_vm._s(field.displayName)),
                                          ]),
                                          field.description
                                            ? _c(
                                                "span",
                                                [
                                                  _c("font-awesome-icon", {
                                                    style: {
                                                      color:
                                                        "var(--form-control-placeholder)",
                                                      fontSize: "0.9em",
                                                    },
                                                    attrs: {
                                                      id:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      icon: [
                                                        "far",
                                                        "circle-question",
                                                      ],
                                                    },
                                                  }),
                                                  _c(
                                                    "b-popover",
                                                    {
                                                      attrs: {
                                                        target:
                                                          _vm.componentId +
                                                          "_" +
                                                          field.name,
                                                        triggers: "hover",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            field.description
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    _c("CustomField", {
                                      attrs: {
                                        componentId: _vm.componentId,
                                        field: field,
                                        disabled:
                                          _vm.isReadOnly ||
                                          (_vm.exists &&
                                            !_vm.canEdit(_vm.permissionName, [
                                              field.name,
                                            ])),
                                      },
                                      model: {
                                        value: _vm.contact[field.name],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, field.name, $$v)
                                        },
                                        expression: "contact[field.name]",
                                      },
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }
                        )
                      : _vm._e(),
                    _vm.canView(_vm.permissionName, ["identifier"])
                      ? _c(
                          "b-col",
                          {
                            staticClass: "pl-md-0",
                            attrs: { cols: "12", md: "4" },
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.$t("field.identifier"),
                                  "label-for": "identifier",
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "identifier",
                                        type: "text",
                                        "data-vv-as":
                                          _vm.$t("field.identifier"),
                                        "data-vv-name": "contact.identifier",
                                        maxlength: _vm.maxIdentifierLength,
                                        readonly:
                                          _vm.isReadOnly ||
                                          (!_vm.exists &&
                                            !_vm.canAdd(_vm.permissionName, [
                                              "identifier",
                                            ])) ||
                                          (_vm.exists &&
                                            !_vm.canEdit(_vm.permissionName, [
                                              "identifier",
                                            ])),
                                        trim: "",
                                      },
                                      model: {
                                        value: _vm.contact.identifier,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.contact,
                                            "identifier",
                                            $$v
                                          )
                                        },
                                        expression: "contact.identifier",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.customFieldMap["identifier"] != null
                      ? _vm._l(
                          _vm.customFieldMap["identifier"],
                          function (field, index) {
                            return _c(
                              "b-col",
                              {
                                key: "identifier" + index,
                                staticClass: "pr-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    field.type !== "Boolean"
                                      ? _c("template", { slot: "label" }, [
                                          _c("span", { staticClass: "mr-2" }, [
                                            _vm._v(_vm._s(field.displayName)),
                                          ]),
                                          field.description
                                            ? _c(
                                                "span",
                                                [
                                                  _c("font-awesome-icon", {
                                                    style: {
                                                      color:
                                                        "var(--form-control-placeholder)",
                                                      fontSize: "0.9em",
                                                    },
                                                    attrs: {
                                                      id:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      icon: [
                                                        "far",
                                                        "circle-question",
                                                      ],
                                                    },
                                                  }),
                                                  _c(
                                                    "b-popover",
                                                    {
                                                      attrs: {
                                                        target:
                                                          _vm.componentId +
                                                          "_" +
                                                          field.name,
                                                        triggers: "hover",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            field.description
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    _c("CustomField", {
                                      attrs: {
                                        componentId: _vm.componentId,
                                        field: field,
                                        disabled:
                                          _vm.isReadOnly ||
                                          (_vm.exists &&
                                            !_vm.canEdit(_vm.permissionName, [
                                              field.name,
                                            ])),
                                      },
                                      model: {
                                        value: _vm.contact[field.name],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, field.name, $$v)
                                        },
                                        expression: "contact[field.name]",
                                      },
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }
                        )
                      : _vm._e(),
                    _vm.canView(_vm.permissionName, ["lastName"])
                      ? _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.$t("contact.field.lastName"),
                                  "label-for": "lastName",
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: { required: true },
                                          expression: "{ required: true }",
                                        },
                                      ],
                                      attrs: {
                                        id: "lastName",
                                        type: "text",
                                        "data-vv-as": _vm.$t(
                                          "contact.field.lastName"
                                        ),
                                        "data-vv-name": "contact.lastName",
                                        "data-vv-delay": "500",
                                        readonly:
                                          _vm.isReadOnly ||
                                          (!_vm.exists &&
                                            !_vm.canAdd(_vm.permissionName, [
                                              "identifier",
                                            ])) ||
                                          (_vm.exists &&
                                            !_vm.canEdit(_vm.permissionName, [
                                              "identifier",
                                            ])),
                                        state:
                                          _vm.fieldValidateUtil.stateValidate(
                                            _vm.isReadOnly,
                                            _vm.veeFields,
                                            _vm.errors,
                                            "contact.lastName"
                                          ),
                                      },
                                      model: {
                                        value: _vm.contact.lastName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, "lastName", $$v)
                                        },
                                        expression: "contact.lastName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-invalid-feedback",
                                  {
                                    staticClass:
                                      "alert-danger form-field-alert",
                                    class: { "d-block": _vm.showLastNameError },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: ["far", "circle-exclamation"],
                                      },
                                    }),
                                    _vm._v(
                                      "  " +
                                        _vm._s(
                                          _vm.errors.first("contact.lastName")
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.customFieldMap["lastName"] != null
                      ? _vm._l(
                          _vm.customFieldMap["lastName"],
                          function (field, index) {
                            return _c(
                              "b-col",
                              {
                                key: "lastName" + index,
                                staticClass: "pr-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    field.type !== "Boolean"
                                      ? _c("template", { slot: "label" }, [
                                          _c("span", { staticClass: "mr-2" }, [
                                            _vm._v(_vm._s(field.displayName)),
                                          ]),
                                          field.description
                                            ? _c(
                                                "span",
                                                [
                                                  _c("font-awesome-icon", {
                                                    style: {
                                                      color:
                                                        "var(--form-control-placeholder)",
                                                      fontSize: "0.9em",
                                                    },
                                                    attrs: {
                                                      id:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      icon: [
                                                        "far",
                                                        "circle-question",
                                                      ],
                                                    },
                                                  }),
                                                  _c(
                                                    "b-popover",
                                                    {
                                                      attrs: {
                                                        target:
                                                          _vm.componentId +
                                                          "_" +
                                                          field.name,
                                                        triggers: "hover",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            field.description
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    _c("CustomField", {
                                      attrs: {
                                        componentId: _vm.componentId,
                                        field: field,
                                        disabled:
                                          _vm.isReadOnly ||
                                          (_vm.exists &&
                                            !_vm.canEdit(_vm.permissionName, [
                                              field.name,
                                            ])),
                                      },
                                      model: {
                                        value: _vm.contact[field.name],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, field.name, $$v)
                                        },
                                        expression: "contact[field.name]",
                                      },
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }
                        )
                      : _vm._e(),
                    _vm.customFieldMap["default"] != null
                      ? _vm._l(
                          _vm.customFieldMap["default"],
                          function (field, index) {
                            return _c(
                              "b-col",
                              {
                                key: index,
                                staticClass: "pr-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    field.type !== "Boolean"
                                      ? _c("template", { slot: "label" }, [
                                          _c("span", { staticClass: "mr-2" }, [
                                            _vm._v(_vm._s(field.displayName)),
                                          ]),
                                          field.description
                                            ? _c(
                                                "span",
                                                [
                                                  _c("font-awesome-icon", {
                                                    style: {
                                                      color:
                                                        "var(--form-control-placeholder)",
                                                      fontSize: "0.9em",
                                                    },
                                                    attrs: {
                                                      id:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      icon: [
                                                        "far",
                                                        "circle-question",
                                                      ],
                                                    },
                                                  }),
                                                  _c(
                                                    "b-popover",
                                                    {
                                                      attrs: {
                                                        target:
                                                          _vm.componentId +
                                                          "_" +
                                                          field.name,
                                                        triggers: "hover",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            field.description
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    _c("CustomField", {
                                      attrs: {
                                        componentId: _vm.componentId,
                                        field: field,
                                        disabled:
                                          _vm.isReadOnly ||
                                          (_vm.exists &&
                                            !_vm.canEdit(_vm.permissionName, [
                                              field.name,
                                            ])),
                                      },
                                      model: {
                                        value: _vm.contact[field.name],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, field.name, $$v)
                                        },
                                        expression: "contact[field.name]",
                                      },
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }
                        )
                      : _vm._e(),
                    _vm.canView(_vm.permissionName, ["position"])
                      ? _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.$t("contact.field.position"),
                                  "label-for": "position",
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "position",
                                        type: "text",
                                        readonly:
                                          _vm.isReadOnly ||
                                          (!_vm.exists &&
                                            !_vm.canAdd(_vm.permissionName, [
                                              "position",
                                            ])) ||
                                          (_vm.exists &&
                                            !_vm.canEdit(_vm.permissionName, [
                                              "position",
                                            ])),
                                      },
                                      model: {
                                        value: _vm.contact.position,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, "position", $$v)
                                        },
                                        expression: "contact.position",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.customFieldMap["position"] != null
                      ? _vm._l(
                          _vm.customFieldMap["position"],
                          function (field, index) {
                            return _c(
                              "b-col",
                              {
                                key: "position" + index,
                                staticClass: "pr-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    field.type !== "Boolean"
                                      ? _c("template", { slot: "label" }, [
                                          _c("span", { staticClass: "mr-2" }, [
                                            _vm._v(_vm._s(field.displayName)),
                                          ]),
                                          field.description
                                            ? _c(
                                                "span",
                                                [
                                                  _c("font-awesome-icon", {
                                                    style: {
                                                      color:
                                                        "var(--form-control-placeholder)",
                                                      fontSize: "0.9em",
                                                    },
                                                    attrs: {
                                                      id:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      icon: [
                                                        "far",
                                                        "circle-question",
                                                      ],
                                                    },
                                                  }),
                                                  _c(
                                                    "b-popover",
                                                    {
                                                      attrs: {
                                                        target:
                                                          _vm.componentId +
                                                          "_" +
                                                          field.name,
                                                        triggers: "hover",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            field.description
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    _c("CustomField", {
                                      attrs: {
                                        componentId: _vm.componentId,
                                        field: field,
                                        disabled:
                                          _vm.isReadOnly ||
                                          (_vm.exists &&
                                            !_vm.canEdit(_vm.permissionName, [
                                              field.name,
                                            ])),
                                      },
                                      model: {
                                        value: _vm.contact[field.name],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, field.name, $$v)
                                        },
                                        expression: "contact[field.name]",
                                      },
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }
                        )
                      : _vm._e(),
                    _vm.canView(_vm.permissionName, ["company"])
                      ? _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.$t("contact.field.company"),
                                  "label-for": "company",
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "company",
                                        type: "text",
                                        readonly:
                                          _vm.isReadOnly ||
                                          !_vm.canView(_vm.permissionName, [
                                            "company",
                                          ]),
                                      },
                                      model: {
                                        value: _vm.contact.company,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, "company", $$v)
                                        },
                                        expression: "contact.company",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.customFieldMap["company"] != null
                      ? _vm._l(
                          _vm.customFieldMap["company"],
                          function (field, index) {
                            return _c(
                              "b-col",
                              {
                                key: "company" + index,
                                staticClass: "pr-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    field.type !== "Boolean"
                                      ? _c("template", { slot: "label" }, [
                                          _c("span", { staticClass: "mr-2" }, [
                                            _vm._v(_vm._s(field.displayName)),
                                          ]),
                                          field.description
                                            ? _c(
                                                "span",
                                                [
                                                  _c("font-awesome-icon", {
                                                    style: {
                                                      color:
                                                        "var(--form-control-placeholder)",
                                                      fontSize: "0.9em",
                                                    },
                                                    attrs: {
                                                      id:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      icon: [
                                                        "far",
                                                        "circle-question",
                                                      ],
                                                    },
                                                  }),
                                                  _c(
                                                    "b-popover",
                                                    {
                                                      attrs: {
                                                        target:
                                                          _vm.componentId +
                                                          "_" +
                                                          field.name,
                                                        triggers: "hover",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            field.description
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    _c("CustomField", {
                                      attrs: {
                                        componentId: _vm.componentId,
                                        field: field,
                                        disabled:
                                          _vm.isReadOnly ||
                                          (_vm.exists &&
                                            !_vm.canEdit(_vm.permissionName, [
                                              field.name,
                                            ])),
                                      },
                                      model: {
                                        value: _vm.contact[field.name],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, field.name, $$v)
                                        },
                                        expression: "contact[field.name]",
                                      },
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }
                        )
                      : _vm._e(),
                    _vm.canView("LOCATION") &&
                    _vm.canView(_vm.permissionName, ["LOCATION"])
                      ? _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              { staticClass: "mb-0" },
                              [
                                _c("label", { staticClass: "mr-1" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("project.field.location"))
                                  ),
                                ]),
                                !_vm.isReadOnly &&
                                _vm.canEdit(_vm.permissionName, ["LOCATION"])
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn-action",
                                        on: { click: _vm.locSelectorOpen },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: ["far", "plus"] },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("BadgeGroup", {
                                  staticClass: "mb-3",
                                  attrs: {
                                    readOnly:
                                      _vm.isReadOnly ||
                                      !_vm.canEdit(_vm.permissionName, [
                                        "LOCATION",
                                      ]),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var item = ref.item
                                          var index = ref.index
                                          return [
                                            _c("Badge", {
                                              key: index,
                                              attrs: {
                                                text: item.name,
                                                variant: "primary",
                                                pillable: !!item.pillable,
                                                readOnly:
                                                  _vm.isReadOnly ||
                                                  !_vm.canEdit(
                                                    _vm.permissionName,
                                                    ["LOCATION"]
                                                  ),
                                              },
                                              on: {
                                                badgeRemove: function ($event) {
                                                  return _vm.locationBadgeRemove(
                                                    index
                                                  )
                                                },
                                                badgeClick: function ($event) {
                                                  _vm.canView("LOCATION")
                                                    ? _vm.locationBadgeClick(
                                                        item.uuId
                                                      )
                                                    : ""
                                                },
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    525557721
                                  ),
                                  model: {
                                    value: _vm.location,
                                    callback: function ($$v) {
                                      _vm.location = $$v
                                    },
                                    expression: "location",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.customFieldMap["locations"] != null
                      ? _vm._l(
                          _vm.customFieldMap["locations"],
                          function (field, index) {
                            return _c(
                              "b-col",
                              {
                                key: "locations" + index,
                                staticClass: "pr-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    field.type !== "Boolean"
                                      ? _c("template", { slot: "label" }, [
                                          _c("span", { staticClass: "mr-2" }, [
                                            _vm._v(_vm._s(field.displayName)),
                                          ]),
                                          field.description
                                            ? _c(
                                                "span",
                                                [
                                                  _c("font-awesome-icon", {
                                                    style: {
                                                      color:
                                                        "var(--form-control-placeholder)",
                                                      fontSize: "0.9em",
                                                    },
                                                    attrs: {
                                                      id:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      icon: [
                                                        "far",
                                                        "circle-question",
                                                      ],
                                                    },
                                                  }),
                                                  _c(
                                                    "b-popover",
                                                    {
                                                      attrs: {
                                                        target:
                                                          _vm.componentId +
                                                          "_" +
                                                          field.name,
                                                        triggers: "hover",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            field.description
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    _c("CustomField", {
                                      attrs: {
                                        componentId: _vm.componentId,
                                        field: field,
                                        disabled:
                                          _vm.isReadOnly ||
                                          (_vm.exists &&
                                            !_vm.canEdit(_vm.permissionName, [
                                              field.name,
                                            ])),
                                      },
                                      model: {
                                        value: _vm.contact[field.name],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, field.name, $$v)
                                        },
                                        expression: "contact[field.name]",
                                      },
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }
                        )
                      : _vm._e(),
                    _vm.canView(_vm.permissionName, ["phones"])
                      ? _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              { staticClass: "mb-0" },
                              [
                                _c("label", { staticClass: "mr-1" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("contact.field.phones"))
                                  ),
                                ]),
                                _vm._l(
                                  _vm.contact.phones,
                                  function (phone, index) {
                                    return _c(
                                      "b-row",
                                      { key: index },
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { lg: "" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "b-input-group",
                                                  [
                                                    !(
                                                      (_vm.isReadOnly ||
                                                        (!_vm.exists &&
                                                          !_vm.canAdd(
                                                            _vm.permissionName,
                                                            ["phones"]
                                                          )) ||
                                                        (_vm.exists &&
                                                          !_vm.canEdit(
                                                            _vm.permissionName,
                                                            ["phones"]
                                                          ))) &&
                                                      phone.kind == null
                                                    )
                                                      ? _c(
                                                          "b-input-group-prepend",
                                                          [
                                                            _c(
                                                              "b-form-select",
                                                              {
                                                                attrs: {
                                                                  options:
                                                                    _vm.optionPhoneNrs,
                                                                  disabled:
                                                                    _vm.isReadOnly ||
                                                                    (!_vm.exists &&
                                                                      !_vm.canAdd(
                                                                        _vm.permissionName,
                                                                        [
                                                                          "phones",
                                                                        ]
                                                                      )) ||
                                                                    (_vm.exists &&
                                                                      !_vm.canEdit(
                                                                        _vm.permissionName,
                                                                        [
                                                                          "phones",
                                                                        ]
                                                                      )),
                                                                },
                                                                model: {
                                                                  value:
                                                                    phone.kind,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        phone,
                                                                        "kind",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "phone.kind",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "first",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            "",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              null,
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "contact.select_phone_type"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        readonly:
                                                          _vm.isReadOnly ||
                                                          (!_vm.exists &&
                                                            !_vm.canAdd(
                                                              _vm.permissionName,
                                                              ["phones"]
                                                            )) ||
                                                          (_vm.exists &&
                                                            !_vm.canEdit(
                                                              _vm.permissionName,
                                                              ["phones"]
                                                            )),
                                                        trim: "",
                                                      },
                                                      model: {
                                                        value: phone.data,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            phone,
                                                            "data",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "phone.data",
                                                      },
                                                    }),
                                                    !(
                                                      _vm.isReadOnly ||
                                                      (!_vm.exists &&
                                                        !_vm.canAdd(
                                                          _vm.permissionName,
                                                          ["phones"]
                                                        )) ||
                                                      (_vm.exists &&
                                                        !_vm.canEdit(
                                                          _vm.permissionName,
                                                          ["phones"]
                                                        ))
                                                    )
                                                      ? [
                                                          _c(
                                                            "b-input-group-append",
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "PHONE_BTN_ADD_" +
                                                                      _vm.id +
                                                                      "_" +
                                                                      index,
                                                                    variant:
                                                                      "secondary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.fieldItemAdd(
                                                                          "phones",
                                                                          index,
                                                                          {
                                                                            kind: null,
                                                                            data: null,
                                                                          }
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon: [
                                                                          "far",
                                                                          "plus",
                                                                        ],
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      "PHONE_BTN_ADD_" +
                                                                      _vm.id +
                                                                      "_" +
                                                                      index,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "button.add"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-input-group-append",
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "PHONE_BTN_REMOVE_" +
                                                                      _vm.id +
                                                                      "_" +
                                                                      index,
                                                                    variant:
                                                                      "secondary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.fieldItemRemove(
                                                                          "phones",
                                                                          index,
                                                                          {
                                                                            kind: null,
                                                                            data: null,
                                                                          }
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon: [
                                                                          "far",
                                                                          "trash-can",
                                                                        ],
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      "PHONE_BTN_REMOVE_" +
                                                                      _vm.id +
                                                                      "_" +
                                                                      index,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "button.remove"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.customFieldMap["phones"] != null
                      ? _vm._l(
                          _vm.customFieldMap["phones"],
                          function (field, index) {
                            return _c(
                              "b-col",
                              {
                                key: "phones" + index,
                                staticClass: "pr-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    field.type !== "Boolean"
                                      ? _c("template", { slot: "label" }, [
                                          _c("span", { staticClass: "mr-2" }, [
                                            _vm._v(_vm._s(field.displayName)),
                                          ]),
                                          field.description
                                            ? _c(
                                                "span",
                                                [
                                                  _c("font-awesome-icon", {
                                                    style: {
                                                      color:
                                                        "var(--form-control-placeholder)",
                                                      fontSize: "0.9em",
                                                    },
                                                    attrs: {
                                                      id:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      icon: [
                                                        "far",
                                                        "circle-question",
                                                      ],
                                                    },
                                                  }),
                                                  _c(
                                                    "b-popover",
                                                    {
                                                      attrs: {
                                                        target:
                                                          _vm.componentId +
                                                          "_" +
                                                          field.name,
                                                        triggers: "hover",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            field.description
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    _c("CustomField", {
                                      attrs: {
                                        componentId: _vm.componentId,
                                        field: field,
                                        disabled:
                                          _vm.isReadOnly ||
                                          (_vm.exists &&
                                            !_vm.canEdit(_vm.permissionName, [
                                              field.name,
                                            ])),
                                      },
                                      model: {
                                        value: _vm.contact[field.name],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, field.name, $$v)
                                        },
                                        expression: "contact[field.name]",
                                      },
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }
                        )
                      : _vm._e(),
                    _vm.canView(_vm.permissionName, ["emails"])
                      ? _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              { staticClass: "mb-0" },
                              [
                                _c("label", { staticClass: "mr-1" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("contact.field.emails"))
                                  ),
                                ]),
                                _vm._l(
                                  _vm.contact.emails,
                                  function (emails, index) {
                                    return _c(
                                      "b-row",
                                      { key: index },
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { lg: "" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "b-input-group",
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        id: "email" + index,
                                                        readonly:
                                                          _vm.isReadOnly ||
                                                          (!_vm.exists &&
                                                            !_vm.canAdd(
                                                              _vm.permissionName,
                                                              ["emails"]
                                                            )) ||
                                                          (_vm.exists &&
                                                            !_vm.canEdit(
                                                              _vm.permissionName,
                                                              ["emails"]
                                                            )),
                                                        trim: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.contact.emails[
                                                            index
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.contact.emails,
                                                            index,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "contact.emails[index]",
                                                      },
                                                    }),
                                                    !(
                                                      _vm.isReadOnly ||
                                                      (!_vm.exists &&
                                                        !_vm.canAdd(
                                                          _vm.permissionName,
                                                          ["emails"]
                                                        )) ||
                                                      (_vm.exists &&
                                                        !_vm.canEdit(
                                                          _vm.permissionName,
                                                          ["emails"]
                                                        ))
                                                    )
                                                      ? [
                                                          _c(
                                                            "b-input-group-append",
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "EMAIL_BTN_ADD_" +
                                                                      _vm.id +
                                                                      "_" +
                                                                      index,
                                                                    variant:
                                                                      "secondary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.fieldItemAdd(
                                                                          "emails",
                                                                          index,
                                                                          null
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon: [
                                                                          "far",
                                                                          "plus",
                                                                        ],
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      "EMAIL_BTN_ADD_" +
                                                                      _vm.id +
                                                                      "_" +
                                                                      index,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "button.add"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-input-group-append",
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "EMAIL_BTN_REMOVE_" +
                                                                      _vm.id +
                                                                      "_" +
                                                                      index,
                                                                    variant:
                                                                      "secondary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.fieldItemRemove(
                                                                          "emails",
                                                                          index,
                                                                          null
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon: [
                                                                          "far",
                                                                          "trash-can",
                                                                        ],
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      "EMAIL_BTN_REMOVE_" +
                                                                      _vm.id +
                                                                      "_" +
                                                                      index,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "button.remove"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.customFieldMap["emails"] != null
                      ? _vm._l(
                          _vm.customFieldMap["emails"],
                          function (field, index) {
                            return _c(
                              "b-col",
                              {
                                key: "emails" + index,
                                staticClass: "pr-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    field.type !== "Boolean"
                                      ? _c("template", { slot: "label" }, [
                                          _c("span", { staticClass: "mr-2" }, [
                                            _vm._v(_vm._s(field.displayName)),
                                          ]),
                                          field.description
                                            ? _c(
                                                "span",
                                                [
                                                  _c("font-awesome-icon", {
                                                    style: {
                                                      color:
                                                        "var(--form-control-placeholder)",
                                                      fontSize: "0.9em",
                                                    },
                                                    attrs: {
                                                      id:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      icon: [
                                                        "far",
                                                        "circle-question",
                                                      ],
                                                    },
                                                  }),
                                                  _c(
                                                    "b-popover",
                                                    {
                                                      attrs: {
                                                        target:
                                                          _vm.componentId +
                                                          "_" +
                                                          field.name,
                                                        triggers: "hover",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            field.description
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    _c("CustomField", {
                                      attrs: {
                                        componentId: _vm.componentId,
                                        field: field,
                                        disabled:
                                          _vm.isReadOnly ||
                                          (_vm.exists &&
                                            !_vm.canEdit(_vm.permissionName, [
                                              field.name,
                                            ])),
                                      },
                                      model: {
                                        value: _vm.contact[field.name],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, field.name, $$v)
                                        },
                                        expression: "contact[field.name]",
                                      },
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }
                        )
                      : _vm._e(),
                    _vm.canView(_vm.permissionName, ["websites"])
                      ? _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              { staticClass: "mb-0" },
                              [
                                _c("label", { staticClass: "mr-1" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("contact.field.websites"))
                                  ),
                                ]),
                                _vm._l(
                                  _vm.contact.websites,
                                  function (website, index) {
                                    return _c(
                                      "b-row",
                                      { key: index },
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { lg: "" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "b-input-group",
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        id: "website" + index,
                                                        readonly:
                                                          _vm.isReadOnly ||
                                                          (!_vm.exists &&
                                                            !_vm.canAdd(
                                                              _vm.permissionName,
                                                              ["websites"]
                                                            )) ||
                                                          (_vm.exists &&
                                                            !_vm.canEdit(
                                                              _vm.permissionName,
                                                              ["websites"]
                                                            )),
                                                        trim: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.contact.websites[
                                                            index
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.contact
                                                              .websites,
                                                            index,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "contact.websites[index]",
                                                      },
                                                    }),
                                                    !(
                                                      _vm.isReadOnly ||
                                                      (!_vm.exists &&
                                                        !_vm.canAdd(
                                                          _vm.permissionName,
                                                          ["websites"]
                                                        )) ||
                                                      (_vm.exists &&
                                                        !_vm.canEdit(
                                                          _vm.permissionName,
                                                          ["websites"]
                                                        ))
                                                    )
                                                      ? [
                                                          _c(
                                                            "b-input-group-append",
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "WEBSITE_BTN_ADD_" +
                                                                      _vm.id +
                                                                      "_" +
                                                                      index,
                                                                    variant:
                                                                      "secondary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.fieldItemAdd(
                                                                          "websites",
                                                                          index,
                                                                          null
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon: [
                                                                          "far",
                                                                          "plus",
                                                                        ],
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      "WEBSITE_BTN_ADD_" +
                                                                      _vm.id +
                                                                      "_" +
                                                                      index,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "button.add"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-input-group-append",
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "WEBSITE_BTN_REMOVE_" +
                                                                      _vm.id +
                                                                      "_" +
                                                                      index,
                                                                    variant:
                                                                      "secondary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.fieldItemRemove(
                                                                          "websites",
                                                                          index,
                                                                          null
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon: [
                                                                          "far",
                                                                          "trash-can",
                                                                        ],
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      "WEBSITE_BTN_REMOVE_" +
                                                                      _vm.id +
                                                                      "_" +
                                                                      index,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "button.remove"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.customFieldMap["websites"] != null
                      ? _vm._l(
                          _vm.customFieldMap["websites"],
                          function (field, index) {
                            return _c(
                              "b-col",
                              {
                                key: "websites" + index,
                                staticClass: "pr-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    field.type !== "Boolean"
                                      ? _c("template", { slot: "label" }, [
                                          _c("span", { staticClass: "mr-2" }, [
                                            _vm._v(_vm._s(field.displayName)),
                                          ]),
                                          field.description
                                            ? _c(
                                                "span",
                                                [
                                                  _c("font-awesome-icon", {
                                                    style: {
                                                      color:
                                                        "var(--form-control-placeholder)",
                                                      fontSize: "0.9em",
                                                    },
                                                    attrs: {
                                                      id:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      icon: [
                                                        "far",
                                                        "circle-question",
                                                      ],
                                                    },
                                                  }),
                                                  _c(
                                                    "b-popover",
                                                    {
                                                      attrs: {
                                                        target:
                                                          _vm.componentId +
                                                          "_" +
                                                          field.name,
                                                        triggers: "hover",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            field.description
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    _c("CustomField", {
                                      attrs: {
                                        componentId: _vm.componentId,
                                        field: field,
                                        disabled:
                                          _vm.isReadOnly ||
                                          (_vm.exists &&
                                            !_vm.canEdit(_vm.permissionName, [
                                              field.name,
                                            ])),
                                      },
                                      model: {
                                        value: _vm.contact[field.name],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, field.name, $$v)
                                        },
                                        expression: "contact[field.name]",
                                      },
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }
                        )
                      : _vm._e(),
                    _vm.canView(_vm.permissionName, ["socials"])
                      ? _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              { staticClass: "mb-0" },
                              [
                                _c("label", { staticClass: "mr-1" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("contact.field.socials"))
                                  ),
                                ]),
                                _vm._l(
                                  _vm.contact.socials,
                                  function (social, index) {
                                    return _c(
                                      "b-row",
                                      { key: index },
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { lg: "" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "b-input-group",
                                                  [
                                                    !(
                                                      (_vm.isReadOnly ||
                                                        (!_vm.exists &&
                                                          !_vm.canAdd(
                                                            _vm.permissionName,
                                                            ["socials"]
                                                          )) ||
                                                        (_vm.exists &&
                                                          !_vm.canEdit(
                                                            _vm.permissionName,
                                                            ["socials"]
                                                          ))) &&
                                                      social.kind == null
                                                    )
                                                      ? _c(
                                                          "b-input-group-prepend",
                                                          [
                                                            _c(
                                                              "b-form-select",
                                                              {
                                                                attrs: {
                                                                  options:
                                                                    _vm.optionSocials,
                                                                  disabled:
                                                                    _vm.isReadOnly ||
                                                                    (!_vm.exists &&
                                                                      !_vm.canAdd(
                                                                        _vm.permissionName,
                                                                        [
                                                                          "socials",
                                                                        ]
                                                                      )) ||
                                                                    (_vm.exists &&
                                                                      !_vm.canEdit(
                                                                        _vm.permissionName,
                                                                        [
                                                                          "socials",
                                                                        ]
                                                                      )),
                                                                },
                                                                model: {
                                                                  value:
                                                                    social.kind,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        social,
                                                                        "kind",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "social.kind",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "first",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            "",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              null,
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "contact.select_socialnet_type"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        readonly:
                                                          _vm.isReadOnly ||
                                                          (!_vm.exists &&
                                                            !_vm.canAdd(
                                                              _vm.permissionName,
                                                              ["socials"]
                                                            )) ||
                                                          (_vm.exists &&
                                                            !_vm.canEdit(
                                                              _vm.permissionName,
                                                              ["socials"]
                                                            )),
                                                        trim: "",
                                                      },
                                                      model: {
                                                        value: social.data,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            social,
                                                            "data",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "social.data",
                                                      },
                                                    }),
                                                    !(
                                                      _vm.isReadOnly ||
                                                      (!_vm.exists &&
                                                        !_vm.canAdd(
                                                          _vm.permissionName,
                                                          ["socials"]
                                                        )) ||
                                                      (_vm.exists &&
                                                        !_vm.canEdit(
                                                          _vm.permissionName,
                                                          ["socials"]
                                                        ))
                                                    )
                                                      ? [
                                                          _c(
                                                            "b-input-group-append",
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "SOCIAL_BTN_ADD_" +
                                                                      _vm.id +
                                                                      "_" +
                                                                      index,
                                                                    variant:
                                                                      "secondary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.fieldItemAdd(
                                                                          "socials",
                                                                          index,
                                                                          {
                                                                            kind: null,
                                                                            data: null,
                                                                          }
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon: [
                                                                          "far",
                                                                          "plus",
                                                                        ],
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      "SOCIAL_BTN_ADD_" +
                                                                      _vm.id +
                                                                      "_" +
                                                                      index,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "button.add"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-input-group-append",
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "SOCIAL_BTN_REMOVE_" +
                                                                      _vm.id +
                                                                      "_" +
                                                                      index,
                                                                    variant:
                                                                      "secondary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.fieldItemRemove(
                                                                          "socials",
                                                                          index,
                                                                          {
                                                                            kind: null,
                                                                            data: null,
                                                                          }
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon: [
                                                                          "far",
                                                                          "trash-can",
                                                                        ],
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      "SOCIAL_BTN_REMOVE_" +
                                                                      _vm.id +
                                                                      "_" +
                                                                      index,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "button.remove"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.customFieldMap["socials"] != null
                      ? _vm._l(
                          _vm.customFieldMap["socials"],
                          function (field, index) {
                            return _c(
                              "b-col",
                              {
                                key: "socials" + index,
                                staticClass: "pr-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    field.type !== "Boolean"
                                      ? _c("template", { slot: "label" }, [
                                          _c("span", { staticClass: "mr-2" }, [
                                            _vm._v(_vm._s(field.displayName)),
                                          ]),
                                          field.description
                                            ? _c(
                                                "span",
                                                [
                                                  _c("font-awesome-icon", {
                                                    style: {
                                                      color:
                                                        "var(--form-control-placeholder)",
                                                      fontSize: "0.9em",
                                                    },
                                                    attrs: {
                                                      id:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      icon: [
                                                        "far",
                                                        "circle-question",
                                                      ],
                                                    },
                                                  }),
                                                  _c(
                                                    "b-popover",
                                                    {
                                                      attrs: {
                                                        target:
                                                          _vm.componentId +
                                                          "_" +
                                                          field.name,
                                                        triggers: "hover",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            field.description
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    _c("CustomField", {
                                      attrs: {
                                        componentId: _vm.componentId,
                                        field: field,
                                        disabled:
                                          _vm.isReadOnly ||
                                          (_vm.exists &&
                                            !_vm.canEdit(_vm.permissionName, [
                                              field.name,
                                            ])),
                                      },
                                      model: {
                                        value: _vm.contact[field.name],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.contact, field.name, $$v)
                                        },
                                        expression: "contact[field.name]",
                                      },
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }
                        )
                      : _vm._e(),
                    _vm.exists &&
                    _vm.canView("NOTE") &&
                    _vm.canView(_vm.permissionName, ["NOTE"])
                      ? _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("NoteList", {
                                  attrs: {
                                    readOnly:
                                      _vm.isReadOnly ||
                                      !_vm.canEdit(_vm.permissionName, [
                                        "NOTE",
                                      ]),
                                    notes: _vm.notes,
                                  },
                                  on: {
                                    add: _vm.addNote,
                                    edit: _vm.editNote,
                                    toRemove: _vm.removeNote,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ],
        ],
        2
      ),
      _c("LocationSelectorModal", {
        attrs: { show: _vm.state.locSelectorShow, multiple: false },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "locSelectorShow", $event)
          },
          ok: _vm.locSelectorOk,
        },
      }),
      _vm.exists
        ? [
            _c("GenericHistoryModal", {
              attrs: {
                show: _vm.state.historyShow,
                id: _vm.id,
                entityType: "CONTACT",
                customFields: _vm.customFields,
              },
              on: {
                "update:show": function ($event) {
                  return _vm.$set(_vm.state, "historyShow", $event)
                },
              },
            }),
            _vm.state.noteShow
              ? _c("NoteModal", {
                  attrs: { show: _vm.state.noteShow, note: _vm.note },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "noteShow", $event)
                    },
                    toAdd: _vm.toAddNote,
                    toUpdate: _vm.toUpdateNote,
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }